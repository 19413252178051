import React from "react"
// import { useState, useEffect } from "react"
import axios from "axios"
import { getCookie, getBookMarkList, getUser, authenticate, isAuth } from '../components/helpers';
// import { getUser, isAuth, getCookie } from "./helpers"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"
import ArticlePreview from "../components/articlePreview"
export default class PersonList extends React.Component {
  state = {
    articles: [],
  }

  componentDidMount() {
    //axios.get(`https://jsonplaceholder.typicode.com/users`)
    axios({
      method: "POST",
      url: "https://www.kalyanshastra.com/api/scrapbooklist",
      // url: "http://34.219.190.182:3001/scrapbooklist",
      headers: {
        token: getCookie("token"),
      },
      data: {
        emailId: getUser().emailId,
      },
    }).then(res => {
      const articles = res.data.bookmarkList
      // alert(getCookie("token"))
      console.log(articles)
      this.setState({ articles })
    })
  }


  componentDidUpdate() {
    axios({
      method: "POST",
      url: "https://www.kalyanshastra.com/api/scrapbooklist",
      headers: {
        token: getCookie("token"),
      },
      data: {
        emailId: getUser().emailId,
      },
    }).then(res => {
      const articles = res.data.bookmarkList
      this.setState({ articles })
    })
  }

  render() {
    console.log("test", this.state.articles);
    //const articleArray = getBookMarkList();

    //alert(getUser().emailId);

    return (
      <div>
        <SEO title="Kalyan Feeds" />
        <div className="row backNavGreen">
          <div className="col-md-12" id="navCrumb">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row" id="breadCrumb">
                    <div className="col-12">
                      {" "}
                      <span>
                        <Link to="/">Home</Link>
                        &nbsp;/&nbsp;
                        <Link to="/app/scrapbook">Scrapbook</Link>
                      </span>
                    </div>
                  </div>
                  <div className="row" id="headCrumb">
                    <div className="col-12">
                      {" "}
                      <h4>ScrapBook</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container inner-blog-page">
          <div className="row">
            {this.state.articles.map(article => (
              <div className="col-md-3">
                <ArticlePreview
                  key={article.id}
                  articleId={article.articleId}
                  title={article.title}
                  path={article.path}
                  tag={article.tag}
                  imagePath="drupal"
                  image={"https://drupal.kalyanshastra.com" + article.image}
                  summary={article.summary.substring(0, 300)}
                  isBookMarked={true}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
