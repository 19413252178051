import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import PropTypes from "prop-types"
import starIcon from "../images/star.png"
import activeStarIcon from "../images/star-active.png"
import shareIcon from "../images/share.png"
import Img from "gatsby-image"
import Image from "react-image"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import axios from "axios"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"
import { getCookie, getBookMarkList, getUser, authenticate, isAuth, updateBookMarkList } from '../components/helpers';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

// import {
//     FacebookShareCount,
//     OKShareCount,
//     PinterestShareCount,
//     RedditShareCount,
//     TumblrShareCount,
//     VKShareCount,
// } from "react-share";

import {
  //EmailIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"



export default class ArticlePreview extends React.Component {

  // state = {
  //   articles: [],
  //   view: false
  // }


  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      isMarked: ''  // this.props.isBookMarked // ,articles: []
    };
  } 


  componentDidMount() {
    let articleArray = getBookMarkList(); 
    this.setState({ isMarked: (articleArray.indexOf(this.props.articleId) != -1 && isAuth()) ? true  : false });
  }


  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }


  addScraBook() {
    // alert();
    const token = getCookie("token");

    if (!isAuth()) {
      toast.error('Login to add an Article to Scrapbook');
    }
    else {
      // alert(this.props.articleId);

      axios({
        method: "POST",
        url: "https://www.kalyanshastra.com/api/scrapbook",
        headers: {
          token: token,
        },
        data: {
          emailId: getUser().emailId,
          articleId: this.props.articleId,
        },
      })
        .then(response => {
          
          console.log("Scrapbook bookmarklist", response.data.bookmarklist);

          if (response.data.status == "1") {
            toast.success(this.props.title + " " + ' - Added to Scrapbook')
          } else {
            toast.error(this.props.title + " " + 'Removed from Scrapbook')
          }
          this.setState({
            isMarked: !this.state.isMarked
          })
 
          // Update Scrapbook List   
          updateBookMarkList(response); 

        })
        .catch(error => {
          console.log("Scrapbook", error)
          toast.error(error)
        })
    }

  }


  render() {

    // const articleArray = ["15201125-979b-474a-b77a-c36c2b6e3c54","4ff8bb57-ffa0-4eb1-b113-bb57e048a9c6"];
    // const articleArray = ["1"];
    const shareUrl = "https://www.kalyanshastra.com"
    const imageUrl = "https://www.kalyanshastra.com" + this.props.image.src
    const pinUrl =
      "https://in.pinterest.com/pin/create/button/?description=" +
      this.props.title +
      "&media=" +
      imageUrl +
      "&url=" +
      shareUrl +
      this.props.path;




    // alert(this.state.view);




    console.log("test", this.state.articles)
    return (
      <React.Fragment>
        <div className="card-navigation">
          {" "}
          <div className="card imgCard">
            {/* <Link to={this.props.path}>
            <Img className="image-align" fluid={this.props.image} alt={this.props.alt} />{" "}
          </Link> */}
            {(() => {
              if (this.props.imagePath) {
                return (
                  <Link to={this.props.path}>
                    <Image
                      className="image-align loaderImg"
                      src={this.props.image}
                      alt={this.props.alt}
                      loader={
                        <Loader
                          type="Rings"
                          color="#fae83d"
                          height={200}
                          width={200}
                          className="loading"
                        />
                      }
                    />{" "}
                  </Link>
                )
              } else {
                return (
                  <Link to={this.props.path}>
                    <Img className="image-align" fluid={this.props.image} alt={this.props.alt} />{" "}
                  </Link>
                )
              }
            })()}
            <div className="card secCard">
              <div className="card-body">
                <h5 className={this.props.tag}>{this.props.tag}</h5>
                <div className="infoText">
                  <Link to={this.props.path}> {this.props.title} </Link>
                  <br />
                  <div className="page-icon landing-page">

                    {/* <span onClick={this.addScraBook.bind(this)}>
                      <img src={starIcon} alt="" />
                    </span> */}

                    {/* if (articleArray.indexOf(this.props.articleId) != -1 && !this.state.isMarked) { */}
                    {(() => {
                      if (this.state.isMarked) {
                        return (
                          <span onClick={this.addScraBook.bind(this)}>
                            <img src={activeStarIcon} alt="" />
                          </span>
                        )
                      } else {
                        return (
                          <span onClick={this.addScraBook.bind(this)}>
                            <img src={starIcon} alt="" />
                          </span>
                        )
                      }
                    })()}


                    <span>
                      <img src={shareIcon} alt="" onClick={this.toggleHidden.bind(this)} />
                    </span>
                    {this.state.isHidden === true && (
                      <div className="share-container">
                        <div className="share-content">
                          <ul>
                            <li>
                              <FacebookShareButton
                                // url={'https://www.facebook.com/sharer/sharer.php?u='+baseUrl+post.path.alias}
                                url={"https://www.kalyanshastra.com/" + this.props.path}
                              >
                                <FacebookIcon
                                  size={30}
                                  round={true}
                                  openShareDialogOnClick={true}
                                />
                              </FacebookShareButton>
                            </li>
                            <li>
                              <TwitterShareButton
                                // url={'https://www.facebook.com/sharer/sharer.php?u='+baseUrl+post.path.alias}
                                url={"https://www.kalyanshastra.com/" + this.props.path}
                              >
                                <TwitterIcon
                                  size={30}
                                  round={true}
                                  openShareDialogOnClick={true}
                                />
                              </TwitterShareButton>
                            </li>
                            <li>
                              <WhatsappShareButton
                                // url={'https://www.facebook.com/sharer/sharer.php?u='+baseUrl+post.path.alias}
                                url={"https://www.kalyanshastra.com/" + this.props.path}
                              >
                                <WhatsappIcon
                                  size={30}
                                  round={true}
                                  openShareDialogOnClick={true}
                                />
                              </WhatsappShareButton>
                            </li>

                            <li>
                              <button className="btn p-0">
                                <a href={pinUrl} target="_blank">
                                  <PinterestIcon
                                    size={30}
                                    round={true}
                                    openShareDialogOnClick={true}
                                  />
                                </a>
                              </button>
                            </li>

                            {/* <li><InstapaperIcon size={30} round={true} url={shareUrl} />
                                            </li>
                                            <li><PinterestIcon size={30} round={true} url={shareUrl} /></li>
                                            <li><LinkedinIcon size={30} round={true} url={shareUrl} /></li>
                                            <li><LivejournalIcon size={30} round={true} url={shareUrl} /></li>
                                            <li><MailruIcon size={30} round={true} url={shareUrl} /></li> */}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>


      </React.Fragment>
    )
  }
}














// const ArticlePreview = ({
//   articleId,
//   title,
//   path,
//   image,
//   alt,
//   summary,
//   tag,
//   imageSrc,
//   imagePath,
//   isBookMarked
// }) => {

//   //console.log(imagePath)

//   const [values, setValues] = useState({ 
//     view:false,
//     isBookMark:false
//   });

//   const {view, isBookMark } = values;

//   const articleArray = ["15201125-979b-474a-b77a-c36c2b6e3c54","4ff8bb57-ffa0-4eb1-b113-bb57e048a9c6"];

//    //const [view, setView] = useState(false)

//   const shareUrl = "https://www.kalyanshastra.com"
//   const imageUrl = "https://www.kalyanshastra.com" + image.src
//   const pinUrl =
//     "https://in.pinterest.com/pin/create/button/?description=" +
//     title +
//     "&media=" +
//     imageUrl +
//     "&url=" +
//     shareUrl +
//     path

//   function showHideShare() { 
//    setValues({ ...values, view: !view }); 
//   }

//   // if(articleArray.indexOf(articleId) != -1) {
//   //   setValues({ ...values, isBookMark:true }); 
//   // } else {
//   //   setValues({ ...values, isBookMark:false }); 
//   // }


//   function addScraBook() {

//     alert(isBookMark);
//     // alert(articleId);  
//     setValues({ ...values, isBookMarked: !isBookMarked });

//     console.log('isBookMarked',isBookMarked);

//     if(getUser().emailId) {
//       const token = getCookie("token")
//       axios({
//         method: "POST",
//         url: "https://www.kalyanshastra.com/api/scrapbook",
//         headers: {
//           token: token,
//         },
//         data: {
//           emailId: getUser().emailId,
//           articleId: articleId,
//         },
//       })
//         .then(response => {
//           console.log("Scrapbook", response)
//           if (response.status == "1") {
//             toast.success(title + " " + response.data.message)
//           } else {
//             toast.error(title + " " + response.data.message)
//           }
//         })
//         .catch(error => {
//           console.log("Scrapbook", error)
//           toast.error(error)
//         })
//     } else {
//       toast.error('Please Login to Mark Article'); // alert('Please Login IN');
//     }

//   }

//   const ImageUrl = "https://www.kalyanmatrimony.com" + image.src

//   return (
//     <React.Fragment>
//       <div className="card-navigation">
//         {" "}
//         <div className="card imgCard"> 
//           {(() => {
//             if (imagePath) {
//               return (
//                 <Link to={this.props.path}>
//                   <Image
//                     className="image-align loaderImg"
//                     src={this.props.image}
//                     alt={this.props.alt}
//                     loader={
//                       <Loader
//                         type="Rings"
//                         color="#fae83d"
//                         height={200}
//                         width={200}
//                         className="loading"
//                       />
//                     }
//                   />{" "}
//                 </Link>
//               )
//             } else {
//               return (
//                 <Link to={this.props.path}>
//                   <Img className="image-align" fluid={this.props.image} alt={this.props.alt} />{" "}
//                 </Link>
//               )
//             }
//           })()}
//           <div className="card secCard">
//             <div className="card-body">
//               <h5 className={this.props.tag}>{this.props.tag}</h5>
//               <div className="infoText">
//               <Link to={this.props.path}> {this.props.title} | {isBookMarked} </Link>
//                 <br />
//                 <div className="page-icon landing-page">
//                   {(() => {
//             if (articleArray.indexOf(articleId) != -1) {
//               return (
//                 <span onClick={addScraBook}>
//                 <img src={activeStarIcon} alt="" />
//               </span>
//               )
//             } else {
//               return (
//                 <span onClick={addScraBook}>
//                 <img src={starIcon} alt="" />
//               </span>
//               )
//             }
//           })()}





//                   <span>
//                     <img src={shareIcon} alt="" onClick={showHideShare} />
//                   </span>
//                   {view === true && (
//                     <div className="share-container">
//                       <div className="share-content">
//                         <ul>
//                           <li>
//                             <FacebookShareButton
//                               url={"https://www.kalyanshastra.com/" + this.props.path}
//                             >
//                               <FacebookIcon
//                                 size={30}
//                                 round={true}
//                                 openShareDialogOnClick={true}
//                               />
//                             </FacebookShareButton>
//                           </li>
//                           <li>
//                             <TwitterShareButton
//                               url={"https://www.kalyanshastra.com/" + this.props.path}
//                             >
//                               <TwitterIcon
//                                 size={30}
//                                 round={true}
//                                 openShareDialogOnClick={true}
//                               />
//                             </TwitterShareButton>
//                           </li>
//                           <li>
//                             <WhatsappShareButton
//                               url={"https://www.kalyanshastra.com/" + this.props.path}
//                             >
//                               <WhatsappIcon
//                                 size={30}
//                                 round={true}
//                                 openShareDialogOnClick={true}
//                               />
//                             </WhatsappShareButton>
//                           </li>

//                           <li>
//                             <button className="btn p-0">
//                               <a href={pinUrl} target="_blank">
//                                 <PinterestIcon
//                                   size={30}
//                                   round={true}
//                                   openShareDialogOnClick={true}
//                                 />
//                               </a>
//                             </button>
//                           </li> 
//                         </ul>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>{" "}
//       </div>
//     </React.Fragment>
//   )
// }

// ArticlePreview.propTypes = {
//   articleId: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   path: PropTypes.string.isRequired,
//   image: PropTypes.string.isRequired,
//   alt: PropTypes.string.isRequired,
//   summary: PropTypes.string.isRequired,
// }

// export default ArticlePreview
