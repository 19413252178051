import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import SEO from "../components/seo"
// import { handleLogin, isLoggedIn } from "../services/auth"
import axios from "axios"
import { isAuth } from "../components/helpers"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// if (isLoggedIn()) {
//   navigate(`/app/scrapbook`)
// }

// class Register extends React.Component {
const Register = () => {
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  })

  if (isAuth()) {
    navigate(`/app/scrapbook`)
  }

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  )
  const { firstname, lastname, email, password, confirm_password } = values
  const handleUpdate = name => event => {
    // console.log(event.target.value)
    setValues({ ...values, [name]: event.target.value })
  }
  const validation = () => {
    if (firstname === "") {
      toast.error("Firstname is  Required")
    } else if (lastname === "") {
      toast.error("Lastname is Required")
    } else if (email === "") {
      toast.error("Email is  Required")
    } else if (validEmailRegex.test(email) !== true) {
      toast.error("Enter a valid email ID")
    } else if (password === "") {
      toast.error("Password is required")
    } else if (password.length < 8) {
      toast.error("Password must be atleast 8 characters long")
    } else if (confirm_password === "") {
      toast.error("Confirm your password")
    } else if (password !== confirm_password) {
      toast.warning("Passwords do not match")
    } else {
      return true
    }
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (validation() == true) {
      axios({
        method: "POST",
        // url: "https://34.219.190.182:3001/create-user",
        url: "https://www.kalyanshastra.com/api/create-user",
        data: {
          firstName: firstname,
          lastName: lastname,
          emailId: email,
          password: password,
        },
      })
        .then(response => {
          toast.success("Registration completed!")
          toast.success(response.data.message)
          navigate(`/app/login`)
        })
        .catch(error => {
          toast.error(error.response.data.message)
          console.log(error)
        })
    }
  }
  return (
    <React.Fragment>
      <SEO title="Register" />
      <div className="row backNavGreen">
        <div className="col-md-12" id="navCrumb">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row" id="breadCrumb">
                  <div className="col-md-12">
                    <span>
                      <Link to="/">Home</Link>
                      &nbsp;/&nbsp;
                      <Link to="/articles">Register</Link>
                    </span>
                  </div>
                </div>
                <div className="row" id="headCrumb">
                  <div className="col-md-12">
                    <h4>Register</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-5 mt-3">
            <form>
              <div className="form-group">
                <label>Firstname </label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  className="form-control"
                  required
                  value={firstname}
                  onChange={handleUpdate("firstname")}
                />
              </div>
              <div className="form-group">
                <label>Lastname </label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Enter your second name"
                  className="form-control"
                  required
                  value={lastname}
                  onChange={handleUpdate("lastname")}
                />
              </div>
              <div className="form-group">
                <label>Email </label>
                <input
                  type="email"
                  name="emailId"
                  placeholder="Enter your email address"
                  className="form-control"
                  required
                  value={email}
                  onChange={handleUpdate("email")}
                />
              </div>
              <div className="form-group">
                <label>Password </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  className="form-control"
                  required
                  value={password}
                  onChange={handleUpdate("password")}
                />
              </div>
              <div className="form-group">
                <label>Confirm Password </label>
                <input
                  type="password"
                  name="confirm_password"
                  placeholder="Re-enter your password"
                  className="form-control"
                  required
                  value={confirm_password}
                  onChange={handleUpdate("confirm_password")}
                />
              </div>
              <button className="btn btn-ka" onClick={handleSubmit}>
                Register
              </button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
  //   }
}
export default Register
