import React, { PureComponent } from 'react'
import { getUser } from "./helpers"
import SEO from "../components/seo";
import { Link } from "gatsby"

class Scraplist extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <>
            <SEO title="ScrapBook" />
            <div className='row backNavGreen'>
              <div className="col-md-12" id="navCrumb">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="row" id="breadCrumb">
                        <div className="col-12">  <span>
                          <Link
                            to="/"  >
                            Home
                                                </Link>
                          &nbsp;/&nbsp;
                                                <Link
                            to="/scrapbooklist"  >
                            ScrapBookList
                                                </Link>
                        </span>
                        </div>
                      </div>
                      <div className="row" id="headCrumb">
                        <div className="col-12">   <h4>Scrap Book List</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className="container inner-blog-page">
              <div className="row">
                <div className='col-md-12'>
                  <h2> Coming Soon ... </h2>
                  <h1>Your profile</h1>
                  <ul>
                    <li> Name :  {getUser().firstName}
                    </li>
                    <li>E-mail: {getUser().emailId}</li>
                  </ul>
                </div>
              </div>
            </div>
            </>
        )
    }
}

export default Scraplist