import React, { useState } from 'react'
import { navigate, Link } from "gatsby"
import SEO from "../components/seo";
// import { handleLogin, isLoggedIn } from "../services/auth"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import axios from 'axios';
import { authenticate, isAuth } from './helpers';

//class Login extends React.Component {
const Login = ({ history }) => {

  const [values, setValues] = useState({
    email: '',
    password: '',
    buttonText: 'Login'
  });

  const { email, password, buttonText } = values;

  const handleChange = name => event => {
    console.log(event.target.value);
    setValues({ ...values, [name]: event.target.value });
  };

  // const informParent = response => {
  //   authenticate(response, () => {
  //     isAuth() && isAuth().role === 'admin' ? history.push('/admin') : history.push('/private');
  //   });
  // };


  const clickSubmit = event => {
    event.preventDefault();
    setValues({ ...values, buttonText: 'Submitting' });    
 //   toast.success('Data Submitted');    
    
    // alert('');

    axios({
        method: 'POST',
        url:'https://www.kalyanshastra.com/api/login',
        // url:'http://34.219.190.182:3001/login',
        // data: { email, password }
        data: {
          emailId: email,
          password: password,
        },
    })
        .then(response => {
            console.log('SIGNIN SUCCESS', response);
            // / save the response (user, token) localstorage/cookie
            authenticate(response, () => {
                setValues({ ...values, name: '', email: '', password: '', buttonText: 'Submitted' });
                // toast.success(`Hey ${response.data.user.name}, Welcome back!`);
                // isAuth() && isAuth().role === 'admin' ? history.push('/admin') : history.push('/private');
                navigate(`/app/scrapbook`);
            });
        })
        .catch(error => {
            console.log('SIGNIN ERROR');
            // console.log('SIGNIN ERROR', error.response.data);
            setValues({ ...values, buttonText: 'Submit' });
            // toast.error(error.response.data.error);
        });
};



  // state = {
  //   username: ``,
  //   password: ``,
  // }
  // handleUpdate = event => {
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   })
  // }
  // handleSubmit = event => {
  //   event.preventDefault()
  //   handleLogin(this.state)
  // }


  // render  {

    // if (isLoggedIn()) {
    //   navigate(`/app/scrapbook`)
    // }
    return (
      <React.Fragment>
        <SEO title="Login" />
        <div className='row backNavGreen'>
          <div className="col-md-12" id="navCrumb">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row" id="breadCrumb">
                    <div className="col-md-12">
                      <span>
                        <Link
                          to="/"  >
                          Home
                                        </Link>
                        &nbsp;/&nbsp;
                                        <Link
                          to="/articles"  >
                          Login
                                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="row" id="headCrumb">
                    <div className="col-md-12">
                      <h4>Login</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className="col-md-5 mt-3">

              <form method="post"  >
                <div class="form-group">
                  <label>
                    Username </label>
                  <input type="text" name="username" placeholder='Enter username' 
                  className="form-control" onChange={handleChange('email')} />

                </div>
                <div class="form-group">
                  <label>
                    Password  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder='Enter password'
                    className="form-control"
                    onChange={handleChange('password')}
                  />

                </div>
                {/* <button type="submit" class="btn btn-ka">Log In</button> */}

                <button className="btn btn-ka" onClick={clickSubmit}>
                    {buttonText}
                </button>

              </form>
            </div>
          </div> 
        </div>
      </React.Fragment>
    );
  };
// }
export default Login