import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Scrapbook from "../components/scrapbook"
import ScrapbookList from "../components/scraplist"
import Login from "../components/login"
import Register from "../components/register"
const App = () => (
  <Layout>
    <Router basepath='/app'>
      <PrivateRoute path="/scrapbook" component={Scrapbook} />
      <PrivateRoute path="/scrapbooklist" component={ScrapbookList} />
      <Login path="/login" component={Login} />
      <Register path="/register" component={Register} />
    </Router>
  </Layout>
)
export default App